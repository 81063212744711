<template>
  <AppPage hideBottomBar hideAppBar container>
    <div class="auth-container">
      <div class="text-center">
        <img src="@/assets/svg/check-outline.svg" alt="" />

        <h3 class="my-5 px-5">
          {{ $vuetify.lang.t("$vuetify.password_updated_successfully") }}
        </h3>

        <app-button @click="login">{{
          $vuetify.lang.t("$vuetify.login")
        }}</app-button>
      </div>
    </div>

    <img src="@/assets/images/three-paws.png" class="three-paws" alt="" />
  </AppPage>
</template>

<script>
export default {
  name: "password-success",
  data() {
    return {};
  },
  methods: {
    login() {
      this.$router.replace("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  display: grid;
  place-items: center;
  height: calc(100vh - 2rem);
}
</style>
